/* eslint-disable max-len */
import Vue from 'vue';

import Footer from '@/components/Shared/Footer/Footer';
import ClientNav from '@/components/Clients/ClientNav';
import './Terms.css';

const Terms = Vue.extend({
  render() {
    return (
      <div class="terms">
        <ClientNav />

        <div class="terms__content">
          <h1>TEEFAL STUDIOS | Contract &amp; Social Media Consent Form</h1>

          <p>By booking a session, you have confirmed your agreement to the following:</p>

          <p>
            50% payment is required to book a date and the balance is due immediately after the shoot. Images will be ready between 5-7 days after session. Frames and photo books will be ready 2 weeks after client selects images to be used.
          </p>

          <p>
            CLIENT will inform TEEFAL STUDIOS of any date change or cancellation and 25% of total bill will be deducted. 100% refund to CLIENT will be made if TEEFAL STUDIOS cancels. There is no refund after any session. Studio Watermarks being removed from images will attract 30% of the price of the session fee.
          </p>

          <p>
            TEEFAL STUDIOS retains copyright of the photographs and hereby grants the client unlimited but non-exclusive rights to use or reproduce the photographs for which the client pays. TEEFAL STUDIOS may use CLIENT’s likeness and image on TEEFAL STUDIOS’s website or other advertising.
          </p>

          <p>
            Many of my clients enjoy being featured in the official Teefal Studios social feeds, where they can easily share their images with friends and family!
          </p>

          <p>
            CLIENT hereby assigns TEEFAL STUDIOS the irrevocable and unrestricted right to use and publish photographs of the client or in which the client may be included, for editorial, trade, advertising and any other purpose and in any manner and medium.
          </p>

          <p>
            The final postproduction and editing styles, effects, and overall look of the images are left to the discretion of TEEFAL STUDIOS. The determination of images delivered to the client is left to the discretion of TEEFAL STUDIOS.  We DO NOT give CLIENTS raw images.
          </p>

          <p>
            CLIENT understands and agrees that TEEFAL STUDIOS is not required to maintain copies of the photos from this shoot 60 (sixty) days after the photos have been delivered to CLIENT.
          </p>

          <p class="highlighted">
            CLIENT agrees and understands that, unless otherwise specified in this Contract, CLIENT is not contracting for a personal service that will be performed by any specific photographer. TEEFAL STUDIOS may sub-contract or assign this contract to any second-shooter; TEEFAL STUDIOS may assign any photographers associated with TEEFAL STUDIOS to perform its duties under this contract.
          </p>

          <p>
            TEEFAL STUDIOS and the client will attempt to resolve any dispute arising out of or relating to this Contract through friendly negotiations and/or mediation.
          </p>
        </div>

        <Footer />
      </div>
    );
  },
});

export default Terms;
