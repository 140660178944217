import Vue from 'vue';

import './Footer.scss';

const Footer = Vue.extend({
  render() {
    return (
      <footer class="footer">
        <section class="footer-mailer">
          <div class="footer-content">
            <h2 class="footer-section__title">Stay In Touch</h2>
            <p class="footer-section__description">
              Sign up for our newsletter to receive updates and exclusive offers
          </p>
          </div>

          <form class="footer-form">
            <input type="email" name="email" placeholder="Email address" />
            <button type="submit">Sign Up</button>
          </form>
        </section>

        <section class="footer-testimonials">
          <h3>Connect With Us</h3>

          {/* <a href="" class="footer-section__title">Facebook</a> */}
          <a href="https://www.instagram.com/teefalstudios/" class="footer-section__title">Instagram</a>
          {/* <a href="" class="footer-section__title">Email</a> */}
        </section>
      </footer>
    );
  },
});

export default Footer;
